import { render, staticRenderFns } from "./DeanRootView.vue?vue&type=template&id=2f1b0dec"
import script from "./DeanRootView.vue?vue&type=script&lang=js"
export * from "./DeanRootView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports